import React from 'react';

import ResourcesCards from '../components/ResourcesCards';
import Edges from '../components/Edges';
import Seo from '../components/Seo';

import PageHeader from '../components/PageHeader';
import useFinancialAssistanceImages from '../hooks/useFinancialAssistanceImages';
import {Link} from "gatsby";

const FinancialAssistancePage = (props) => {
    const { uri } = props;

    const financialAssistanceImages = useFinancialAssistanceImages();

    const pageTitle = 'Financial Assistance Options For BC Trades Students';

    return (
        <>
            <Seo title={pageTitle} description="The information presented here are financial aid sources specifically tailored for BC’s Trades students." />

            <PageHeader title={pageTitle} breadcrumbs={[{ label: pageTitle, url: uri }]} />

            <Edges size="md">
                <div className="container mx-auto">
                    <ResourcesCards financialAssistanceImages={financialAssistanceImages} />
                    <div className="flex max-w-[944px] flex-col px-[10px] md:px-0">
                        <h2>Student Resources:</h2>
                        <p className="mt-[31px]">
                            For students already enrolled in a SkilledTradesBC-approved apprentice program that wish to apply to receive apprentice financial support via WorkBC, watch this how-to video{' '}
                            <a href="https://youtu.be/w1fBPsf9b3I" target="_blank" className="text-blue">Apprentice Online Portal Walkthrough for BC Apprentices</a> to learn the steps to apply.
                        </p>
                        <p className="mt-[9px] mb-[63px]">
                            Students are encouraged to contact a training institution of interest where knowledgeable staff can answer your questions on financial aid, registration procedures and training options.
                            Consortium member institutions are listed <Link to={'/'} className="text-blue">here.</Link>
                        </p>
                    </div>
                </div>
            </Edges>
        </>
    );
};

export default FinancialAssistancePage;
