import { useStaticQuery, graphql } from 'gatsby';

const useFinancialAssistanceImages = () => {
    const { financialAssistanceImages } = useStaticQuery(graphql`
        query FinancialImagesQuery {
            financialAssistanceImages: allFile(filter: { relativeDirectory: { eq: "financial-assistance" } }) {
                nodes {
                    childImageSharp {
                        gatsbyImageData
                    }
                    name
                }
            }
        }
    `);

    return financialAssistanceImages;
};

export default useFinancialAssistanceImages;
