import * as React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';

export default function ResourcesCards(props) {
    const { financialAssistanceImages } = props;
    const resources = [
        {
            title: 'GOVERNMENT RESOURCES:',
            cards: [
                {
                    title: 'Federal Government',
                    text: 'Comprehensive information on Federal Government support for Apprentices in the form of Grants, Loans and Employment Insurance can be found at Government of Canada Apprentice Support Programs.',
                    link: 'https://www.canada.ca/en/services/jobs/training/support-skilled-trades-apprentices/funding-opportunities.html',
                    linkText: 'Learn More',
                    imageName: 'ottawa',
                },
                {
                    title: 'Work BC',
                    text: 'WorkBC: has created a comprehensive ‘one-stop shop’ where apprentices and their employers can learn about Apprentice Financial Supports available for them from Provincial and Federal sources.',
                    link: 'https://www.workbc.ca/Training-Education/Finance-your-Education/Apprenticeship-Financial-Supports.aspx',
                    linkText: 'Learn More',
                    imageName: 'work',
                },
            ],
        },
        {
            title: 'SKILLEDTRADESBC RESOURCES:',
            cards: [
                {
                    title: 'Women in Trades Training Initiative',
                    text: 'Read further details about the Women In Trades initiative and through the emphasis on Money, Opportunity and Satisfaction a pathway to a satisfying Skilled Trades career is being supported.',
                    link: 'https://skilledtradesbc.ca/women-trades/overview',
                    linkText: 'Learn More',
                    imageName: 'women-trades',
                },
                {
                    title: 'Indigenous Peoples in Trades',
                    text: 'With a focus on working with rural and urban communities, the Indigenous Initiatives Team through the formal Indigenous Skills and Employment Training (ISET) holders and Indigenous communities have a number of formal support resources for exploring trades.  Please see these details here.',
                    link: 'https://skilledtradesbc.ca/indigenous-peoples-trades/overview',
                    linkText: 'Learn More',
                    imageName: 'financial',
                },
                {
                    title: 'Grants for Apprentices registered in Red-Seal Trades',
                    text: 'The Apprenticeship Incentive Grant (AIG) is a taxable cash grant worth $1,000 per level up to a maximum of $2,000 for the first two years of your training in a Red Seal program.  Further details can be found here.',
                    link: 'https://skilledtradesbc.ca/grants-tax-credits/grants',
                    linkText: 'Learn More',
                    imageName: 'calculator',
                },
            ],
        },
    ];

    const getGatsbyImageData = (imageName) => {
        const image = financialAssistanceImages.nodes.find((item) => item.name === imageName);
        return image?.childImageSharp.gatsbyImageData;
    };

    return (
        <>
            {resources &&
                resources.map((resource, i) => (
                    <div key={i} className="my-[10px] bg-white px-[10px] md:px-0">
                        <h2 className="mb-[45px]">{resource.title}</h2>
                        <div className="bg-gray-50 relative pb-20 pr-4 sm:pr-6">
                            <div className="relative max-w-7xl">
                                <div className="flex flex-row flex-wrap justify-center gap-[54px] md:flex-nowrap md:justify-start">
                                    {resource.cards.map((card, i) => {
                                        const gatsbyImageData = getGatsbyImageData(card.imageName);
                                        return (
                                            <div key={i} className="flex max-w-[282px] flex-col overflow-hidden rounded-lg shadow-lg">
                                                <div className="flex-shrink-0">
                                                    <GatsbyImage image={gatsbyImageData} alt="" className="h-36 w-full" />
                                                </div>
                                                <div className="flex flex-1 flex-col justify-between bg-white p-6">
                                                    <div className="flex-1">
                                                        <p className="mb-[10px] text-[20px] leading-[28px] text-gray900">{card.title}</p>
                                                        <p className="mb-[45px] text-gray500">{card.text}</p>
                                                    </div>
                                                    <div className="mt-6 flex items-center">
                                                        <a href={`${card.link}`} target="_blank" className="mb-[10px] text-gray500 underline">
                                                            {card.linkText}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <hr style={{ borderTop: '2px solid #C9C9C9', marginBottom: '44px' }} />
                    </div>
                ))}
        </>
    );
}
